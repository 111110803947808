import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLIENT, INCEDENT_TYPES } from "../../../helpers/constants";
import {
  getDeviceName,
  getFormattedUserName,
  getMdnWithoutUnderscore,
  isEmpty,
} from "../../../helpers/formatUtils";
import moment from "moment";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { getEnrolledServiceFee } from "../../../actions/cowrapperapi";
import { TextBold, TextMedium, TextRegular } from "../../../base/BaseText";

import { addJourneyMessages } from "../../../helpers/addJourneyMessages";
import { useTranslate } from "../../../helpers/languageUtils";
import { selectAgreementAsset } from "../state/operators/deviceConfirmationOperators";
import BaseImg from "../../../base/BaseImg/BaseImg";
import {
  createSystemMessage,
  createUserMessage,
} from "../../../helpers/createMessage";
import BaseLabel from "../../../base/BaseLabel";

export default function DeviceList() {
  const { Agreement, TerminatedAgreement } = useSelector((state) => state.validation.verification);  
  const client = useSelector((state) => state.app.client);
  const isSingtel = client === CLIENT.SINGTEL;
  const ClientOfferName = Agreement && Agreement[0] && Agreement[0].ClientOffer?.ClientOfferName;
  const TerminatedClientOfferName = TerminatedAgreement && TerminatedAgreement[0] && TerminatedAgreement[0].ClientOffer?.ClientOfferName;
  const ClientOfferNameToUse = ClientOfferName || TerminatedClientOfferName
  const isSingtelStarter = isSingtel && (ClientOfferNameToUse && ClientOfferNameToUse.toLowerCase().indexOf("starter") > -1)
  const isSingtelStarterTerminated = isSingtelStarter && TerminatedAgreement && TerminatedAgreement.length;
  const t = useTranslate();
  const name = getFormattedUserName(Agreement[0]?.EnrolledName || TerminatedAgreement[0]?.EnrolledName || "User");
  const refs = useRef([]);
  // const sliderMarkersRef = useRef();
  const dispatch = useDispatch();
  const [updateClicked, setUpdateClicked] = useState("");
  const [selectedAsset, setSeletctedAsset] = useState({});
  // const addToRefs = (el) => {
  //   if (el && !refs.current.includes(el)) {
  //     refs.current.push(el);
  //   }
  // };

  const celcomCampaignStartDate = moment(process.env.REACT_APP_CELCOM_CAMPAIGN_START || '2024-07-01', "YYYY-MM-DD");
  const celcomCampaignEndDate = moment(process.env.REACT_APP_CELCOM_CAMPAIGN_END || '2024-10-01', "YYYY-MM-DD");
  const isCelcomCampaignInactive = moment().isBefore(celcomCampaignStartDate, "day") || moment().isSameOrAfter(celcomCampaignEndDate, "day");

  const battRepCampaignEnd = isSingtel ? process.env.REACT_APP_SINGTEL_BATT_CAMPAIGN_END : "";
  const isBattRepEndDateActive = isSingtel ? moment().isSameOrBefore(moment(battRepCampaignEnd), "day") : false;
  const isBattRepCampaign = isSingtel ? isBattRepEndDateActive : false;

  const updateSelectedAsset = (asset) => {
    setSeletctedAsset(asset);
  };

  const filterAllAgreementAssets = () => {
    const uniqueDevices = [];
    const AgreementToUse = isSingtelStarterTerminated ? TerminatedAgreement : Agreement

    AgreementToUse.forEach((agreement) => {
      // Celcom does not have multi imei (multi agreement) feature so we have carrier wise filter logic
      if (isSingtel) {
        let oldAsset = null;
        agreement.AssetsList.Asset.forEach((asset) => {
          const newAsset = { ...asset, agreement };
          if (["ENROLLED", "REPLACED"].includes(asset.AssetInstance)) {
            if (oldAsset) {
              var oldTime = moment(oldAsset.CreatedDate);
              var newTime = moment(newAsset.CreatedDate);
              if (newTime.isAfter(oldTime)) oldAsset = newAsset;
            } else {
              oldAsset = newAsset;
            }
          }
        });
        uniqueDevices.push(oldAsset);
      } else {
        agreement.AssetsList.Asset.forEach((asset) => {
          if (
            asset.AssetInstance === "ENROLLED" ||
            asset.AssetInstance === "REPLACED"
          ) {
            const newAsset = { ...asset, agreement };
            uniqueDevices.push(newAsset);
          }
        });
      }
    });

    return uniqueDevices.sort((a, b) => a.IMEI - b.IMEI);
  };

  const uniqueAgreementAssets = filterAllAgreementAssets();

  useEffect(() => {
    if (refs.current.length > 1) {
      let intersectionOberverOptions = {
        root: document.querySelector(".devicelist-slider"),
        rootMargin: "0px",
        threshold: 0.85,
      };
      const sliderCallback = (entries, observer) => {
        entries.forEach((entry) => {
          const { target } = entry;
          let idx = parseInt(target.getAttribute("slideindex"));
          if (entry.intersectionRatio >= 0.85) {
            refs.current[idx] && refs.current[idx].classList.add("highlighted");
          } else {
            refs.current[idx] &&
              refs.current[idx].classList.remove("highlighted");
          }
        });
      };
      let observer = new IntersectionObserver(
        sliderCallback,
        intersectionOberverOptions
      );
      const deviceSlides = [...document.querySelectorAll(".devicelist-slide")];

      deviceSlides.forEach((section, index) => {
        observer.observe(section);
      });
    } /*else {
      sliderMarkersRef.current.classList.add("hidden");
    }*/
  }, []);

  const handleUpdate = (e, imei = "") => {
    dispatch(
      addJourneyMessages([
        createUserMessage("Text", e.target.innerText),
        createSystemMessage("CHOOSE_EXCHANGE_OPTIONS", {
          showComponent: "selectExchangeOption",
          selectedimei: imei,
        }),
      ])
    );
    setUpdateClicked(imei || "NO_IMEI");
  };

  return (
    <section className="max-w-5xl mx-auto">
      <div className="text-left my-4 text-default">
        <TextRegular>
          {t("DeviceList.Title", { firstName: name.firstName })}
        </TextRegular>
      </div>
      <h3 className="mb-4 text-default">
        {t("DeviceList.EnroledDevice")}&nbsp;
        <span>({uniqueAgreementAssets.length})</span>
      </h3>
      <div className="devicelist-slider webkit-center flex-grow mb-4">
        {uniqueAgreementAssets.map((asset, i) => {
          return (
            <Slide
              id={asset.AssetId}
              key={asset.AssetId}
              agreement={asset.agreement}
              asset={asset}
              slideIndex={i + 1}
              handleUpdate={handleUpdate}
              updateClicked={updateClicked}
              plan={asset.agreement.ClientOffer}
              updateSelectedAsset={updateSelectedAsset}
              selectedAsset={selectedAsset}
              isCelcomCampaignInactive={isCelcomCampaignInactive}
              isBattRepCampaign={isBattRepCampaign}
            ></Slide>
          );
        })}
      </div>
      {/* <div
        className="slide-marker-container flex flex-row items-center justify-center"
        ref={sliderMarkersRef}
      >
        {uniqueAgreementAssets.map((asset) => {
          return (
            <span
              key={`marker-${asset.AssetId}`}
              className="slide-marker inline-block w-2 h-2 rounded-lg bg-gray-300"
              ref={addToRefs}
            ></span>
          );
        })}
      </div> */}
      <div className="text-center mt-4 text-default">
        <TextMedium>{t("DeviceList.CantFind")}</TextMedium>
      </div>
      <div className="text-center text-default">
        <span
          className={`text-srgreen-tertiary cursor-pointer ${
            updateClicked || selectedAsset.AssetId ? "pointer-events-none" : ""
          }`}
          onClick={handleUpdate}
        >
          {t("DeviceList.UpdateBtn")}
        </span>
      </div>
    </section>
  );
}

const Slide = ({
  agreement,
  asset,
  slideIndex,
  plan,
  handleUpdate,
  updateClicked,
  updateSelectedAsset,
  selectedAsset,
  isCelcomCampaignInactive,
  isBattRepCampaign
}) => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const client = useSelector((state) => state.app.client);
  const isSingtel = client === CLIENT.SINGTEL;
  const isStarterPlan = plan.ClientOfferName && plan.ClientOfferName.toLowerCase().indexOf("starter") > -1
  const currency = isSingtel ? "SGD" : "RM";
  const [serviceFees, setServiceFee] = useState("Loading...");
  const {
    interactionData: {
      Interaction: {
        InteractionLineId,
        SessionData: { ClientId, ClientChannelId, ClientName },
      },
    },
  } = useSelector((state) => state.session);

  async function fetchServiceFee() {
    const serviceFeeRes = await dispatch(
      getEnrolledServiceFee(
        ClientId,
        ClientChannelId,
        ClientName,
        InteractionLineId,
        asset.MobileDeviceNumber
      )
    ).catch((e) => {
      setServiceFee("Error in Service Fee");
    });
    if (isEmpty(serviceFeeRes) || isEmpty(serviceFeeRes.GetServiceFeeResponse))
      return;
    const serviceFees = {};
    serviceFeeRes.GetServiceFeeResponse?.ServiceFees?.forEach((e) => {
      serviceFees[e.IncidentType] = e.Fees;
    });
    setServiceFee(serviceFees);
  }

  useEffect(() => {
    fetchServiceFee();
  }, [dispatch]);

  const deviceName = getDeviceName({
    AssetsInformation: asset.AssetsInformation,
    MakeName: asset.Make.Name,
    ModelName: asset.Model.Name,
  });

  const handleProceed = () => {
    dispatch(addJourneyMessages([createUserMessage("TEXT", `${deviceName}`)]));
    dispatch(selectAgreementAsset(agreement, { ...asset, serviceFees }));
    updateSelectedAsset(asset);
  };

  function renderFee(type, fee) {
    return (
      <p>
        {type} <TextBold>{`${fee?.CurrencyCode} ${fee?.TotalAmount}`}</TextBold>
      </p>
    );
  }
  const handleMakeModelUpdate = (e) => {
    handleUpdate(e, asset.IMEI);
  };

  return (
    <article className="devicelist-slide mt-2" slideindex={slideIndex - 1}>
      <div className="border border-gray-400 rounded-2xl p-4 h-full">
        <div className="flex items-center justify-center pb-4">
          <BaseImg make={asset?.Make?.Name} model={asset?.Model?.Name} isSingtel={isSingtel} />
          <div className="flex flex-col pl-3">
            <BaseLabel className="text-left">
              {deviceName || "Device Unknown"}
            </BaseLabel>
            <div className="text-default">
              <TextBold>
                {getMdnWithoutUnderscore(asset.MobileDeviceNumber)}
              </TextBold>
            </div>
          </div>
        </div>
        <div className="border border-gray-400 rounded-md overflow-hidden">
          <table className="w-full text-14px">
            <tbody>
              <tr>
                <th className="p-2 bg-gray-100 text-xl" colSpan="2">
                  {t("DeviceList.CurrentDevice")}
                </th>
              </tr>
              <tr>
                <td className="bg-white pl-3 pr-1 pt-2 pb-1 w-45% text-xl border-none ">
                  {t("DeviceList.RegisteredName")}
                </td>
                <td className="pl-1 pr-1 py-1 text-xl border-none align-middl">
                  {agreement.EnrolledName}
                </td>
              </tr>
              <tr>
                <td className="bg-white pl-3 pr-1 py-1 w-45% text-xl border-none ">
                  {t("DeviceList.IMEINumber")}
                </td>
                <td className="pl-1 pr-1 py-1 text-xl border-none">
                  {asset.IMEI}
                </td>
              </tr>
              <tr>
                <td className="bg-white pl-3 pr-1 py-1 w-45% text-xl border-none align-top">
                  {t("DeviceList.ServiceFee")}
                </td>
                <td className="pl-1 pr-1 py-1 text-xl border-none">
                  {typeof serviceFees === "string" ? (
                    <TextBold>{serviceFees}</TextBold>
                  ) : (
                    <>
                      {/* {renderFee('Swop', serviceFees?.[INCEDENT_TYPES.SWAP])}
                                            {renderFee('Replacement', serviceFees?.[INCEDENT_TYPES.REPLACEMENT])} */}
                      {renderFee(
                        isSingtel
                          ? isStarterPlan
                            ? "Battery Replacement"
                            : "Screen Repair"
                          : "",
                        serviceFees?.[isSingtel 
                          ? (isStarterPlan ? INCEDENT_TYPES.BATTERY_REPLACEMENT : INCEDENT_TYPES.SCREEN_REPAIR) 
                          : INCEDENT_TYPES.FAULT_REPAIR]
                      )}
                      {isSingtel && !isStarterPlan && isBattRepCampaign &&
                        <>
                          {renderFee(
                            "Battery Replacement",
                            serviceFees?.[INCEDENT_TYPES.BATTERY_REPLACEMENT]
                          )}
                        </>
                      }
                    </>
                  )}
                </td>
              </tr>
              {(!isSingtel && !isCelcomCampaignInactive) && (
                <>
                <tr>
                <td colSpan={2} className="bg-gray-100 p-2 text-xl border-none text-center font-bold">
                  {t("HomeBody.Promo.DiscountAppliedNote")}
                </td>
              </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="text-center mt-4">
          {asset.AssetStatus === "ACTV" &&
            (deviceName ? (
              <BaseButton
                className="w-40 m-auto"
                onClick={handleProceed}
                disabled={
                  !!selectedAsset.AssetId ||
                  typeof serviceFees === "string" ||
                  !!updateClicked
                }
                clicked={selectedAsset.AssetId === asset.AssetId}
                text={t("DeviceList.ProceedBtn")}
              ></BaseButton>
            ) : (
              <BaseButton
                className="w-40 m-auto"
                onClick={handleMakeModelUpdate}
                disabled={!!updateClicked}
                clicked={updateClicked === selectedAsset.IMEI}
                text={t("DeviceList.UpdateBtn")}
              ></BaseButton>
            ))}
        </div>
      </div>
    </article>
  );
};
