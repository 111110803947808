import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initModuleTrigger } from "../../actions/initModuleTrigger";
import ENQUIRY_OPTIONS, { EnumEnquiryModules, STARTER_ENQUIRY_OPTIONS, BATT_REPLACEMENT_CAMPAIGN_ENQUIRY_OPTIONS, } from "./Constants";
import BaseLabel from "../../base/BaseLabel";
import BaseAccordian from "../../base/BaseAccordian/BaseAccordian";
import { TextLight } from "../../base/BaseText";
import { useTranslate, Translate } from "../../helpers/languageUtils";
import { isEmpty } from "../../helpers/formatUtils";
import { INCIDENT_PATH_TYPE, CLIENT } from "../../helpers/constants";
import moment from "moment";

const EnquiryOptions = () => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("");
  const [disabled, setDisabled] = useState(false);
  const srType = useSelector((state) => state.serviceRequest.type);
  const { lastCompletedCase }  = useSelector((state) => state.serviceRequest);
  const client = useSelector((state) => state.app.client);
  const isSingtel = client === CLIENT.SINGTEL;
  const { ClientProductSkuNbr } = useSelector((state) => state.validation?.verifyAgreement?.VerifyAgreementsResults?.Agreement);
  const isSingtelStarter = isSingtel && (ClientProductSkuNbr && ClientProductSkuNbr.toLowerCase().indexOf("starter") > -1)

  const { TerminatedAgreement } = useSelector((state) => state.validation.verification); 
  const isSingtelStarterTerminated = isSingtelStarter && TerminatedAgreement && TerminatedAgreement.length;

  const battRepCampaignEnd = isSingtel ? process.env.REACT_APP_SINGTEL_BATT_CAMPAIGN_END : "";
  const isBattRepEndDateActive = isSingtel ? moment().isSameOrBefore(moment(battRepCampaignEnd), "day") : false;
  const isBattRepCampaign = isSingtel ? isBattRepEndDateActive : false;
  
  const t = useTranslate();
  const {
    ServiceRequestType,
    RepairRequest,
    Servicerequests = [],
  } = useSelector((state) => state.serviceRequest.determineIncidentDetails);

  const disabledOptions = () => {
    switch (srType) {
      case INCIDENT_PATH_TYPE.NOT_ELIGIBLE:
        return [
          EnumEnquiryModules.CreateNewRequest,
          EnumEnquiryModules.ResumeRequest,
          EnumEnquiryModules.ChangeSchedule,
          EnumEnquiryModules.CancelRequest,
          EnumEnquiryModules.InWarrantyRequest
        ]
      case INCIDENT_PATH_TYPE.START_SERVICE_REQUEST:
        if (isSingtelStarterTerminated) {
          return [
            EnumEnquiryModules.CreateNewRequest,
            EnumEnquiryModules.ResumeRequest,
            EnumEnquiryModules.ChangeSchedule,
            EnumEnquiryModules.CancelRequest,
          ]
        } else if (isSingtelStarter && Servicerequests.length > 0) {
          return [
            EnumEnquiryModules.CreateNewRequest,
            EnumEnquiryModules.ResumeRequest,
            EnumEnquiryModules.ChangeSchedule,
            EnumEnquiryModules.CancelRequest,
          ]
        } else {
          return [
            EnumEnquiryModules.ResumeRequest,
            EnumEnquiryModules.ChangeSchedule,
            EnumEnquiryModules.CancelRequest,
          ];
        }
      case INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST:
        if (RepairRequest && RepairRequest.RepairStatus !== "RESERVED") {
          return [
            EnumEnquiryModules.CreateNewRequest,
            EnumEnquiryModules.ResumeRequest,
          ];
        } else if (
          (RepairRequest && RepairRequest.RepairStatus === "RESERVED") ||
          (RepairRequest === undefined &&
            (ServiceRequestType === "DEFAULT" ||
              ServiceRequestType === "SCRNREPREQ" ||
              ServiceRequestType === "SURREPREQ"))
        ) {
          if (isSingtelStarter) {
            return [
              EnumEnquiryModules.CreateNewRequest,
              EnumEnquiryModules.ChangeSchedule
            ];
          } else {
            return [
              EnumEnquiryModules.ChangeSchedule
            ];
          }
        } else {
          return [
            EnumEnquiryModules.ResumeRequest,
            EnumEnquiryModules.ChangeSchedule,
          ];
        }
      case INCIDENT_PATH_TYPE.NO_ACTION_REQUIRED:
        return [
          EnumEnquiryModules.CreateNewRequest,
          EnumEnquiryModules.ResumeRequest,
        ];
      default:
        return [];
    }
  };

  function handleClick(option) {
    setSelectedOption(option.name);
    dispatch(initModuleTrigger(option));
    setDisabled(true);
  }

  const inWarrantyFilterDecorator = (fn) => {
    return function () {
      let options = fn();
      if (srType === INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST) {
        options.push(EnumEnquiryModules.InWarrantyRequest);
      } else {
        if (isSingtel) {
          if (Servicerequests.length < 1) {
            if (lastCompletedCase) {
              let lastCompletedCaseShipDate = lastCompletedCase?.ServiceRequests[0]?.ShippingOrder?.ShipmentDate || "";
              let monthsNowDiffShipDate = moment().diff(moment(lastCompletedCaseShipDate), "months", true);
    
              if (isNaN(monthsNowDiffShipDate) || monthsNowDiffShipDate > 12) {
                options.push(EnumEnquiryModules.InWarrantyRequest);
              }
            } else {
              options.push(EnumEnquiryModules.InWarrantyRequest);
            }
          }
        }
      }
      
      return options;
    };
  };

  const covertOptionLabels = (options) => {
    console.log("Non Starter Options")
    return options.map((option) => {
      let { name, label, options, ...rest } = option;
      if (!isEmpty(options)) options = covertOptionLabels(options);
      return {
        ...rest,
        name,
        // label: t(`EnquiryOptions.${name}`),
        label: isBattRepCampaign ? t(`BattReplacementCampaignEnquiryOptions.${name}`) : t(`EnquiryOptions.${name}`),
        options,
      };
    });
  };
  const covertStarterOptionLabels = (options) => {
    console.log("Starter Options")
    return options.map((option) => {
      let { name, label, options, ...rest } = option;
      if (!isEmpty(options)) options = covertStarterOptionLabels(options);
      return {
        ...rest,
        name,
        label: t(`StarterEnquiryOptions.${name}`),
        options,
      };
    });
  };

  const translatedOptions = isSingtelStarter 
  ? covertStarterOptionLabels(STARTER_ENQUIRY_OPTIONS.Options) 
  : covertOptionLabels(isBattRepCampaign ? BATT_REPLACEMENT_CAMPAIGN_ENQUIRY_OPTIONS.Options : ENQUIRY_OPTIONS.Options);

  return (
    <div className="app-EnquireyOptions">
      <BaseLabel>
        <Translate
          i18nKey="EnquiryOptions.Title"
          components={{ light: <TextLight /> }}
        />
      </BaseLabel>
      <BaseAccordian
        options={translatedOptions}
        disabledKeys={inWarrantyFilterDecorator(disabledOptions)()}
        onClick={handleClick}
        selected={selectedOption}
        disabled={disabled}
      />
    </div>
  );
};

export default EnquiryOptions;
